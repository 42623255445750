<template>
  <b-container
    fluid
  >
    <b-row class="mb-4">
      <b-col
        cols="12"
      >
        <b-card
          title="Services"
          class="shadow-sm"
        >
          <b-row>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="3"
              order-md="4"
            >
              <PaleCard
                label="QR Ph"
                variant="success"
                :count="services.qrph"
                :link="{ name: 'admin.services.qrph' }"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="4"
              order-md="5"
            >
              <PaleCard
                label="GCash"
                variant="primary"
                :count="services.gcash"
                :link="{ name: 'admin.services.gcash' }"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="5"
              order-md="6"
            >
              <PaleCard
                label="Swift Pay"
                variant="warning"
                :count="services.swiftpay"
                :link="{ name: 'admin.services.swiftpay' }"
                :loading="fetching"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
      >
        <b-card
          title="Webhooks"
          class="shadow-sm"
        >
          <b-row>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="3"
              order-md="4"
            >
              <PaleCard
                label="Events"
                variant="success"
                :count="webhooks.events"
                :link="{ name: 'admin.webhooks.events' }"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="4"
              order-md="5"
            >
              <PaleCard
                label="Logs"
                variant="primary"
                :count="webhooks.logs"
                :link="{ name: 'admin.webhooks.logs' }"
                :loading="fetching"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import PaleCard from '@/components/custom/PaleCard'

export default {

  name: 'AdminDashboard',

  middleware: ['auth', 'admin'],

  components: {
    PaleCard
  },

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      services: {
        qrph: 0,
        gcash: 0,
        swiftpay: 0
      },
      webhooks: {
        events: 0,
        logs: 0
      }
    }
  },

  mounted () {
    core.index()
  }
}
</script>
